/* eslint-disable no-use-before-define */

import projectService from 'services/project-service';
import { AppThunk } from 'store/reducers';
import { ProjectInfoDTO, ResourceAccessNode } from 'types';

/*
 * Add Project
 */
export const REQUEST_ADD_PROJECT = 'REQUEST_ADD_PROJECT';//FIXME It looks like this action is never used in any reducer
export const RECEIVE_ADD_PROJECT = 'RECEIVE_ADD_PROJECT';
export const ERROR_FETCH_ADD_PROJECT = 'ERROR_FETCH_ADD_PROJECT';

export type RequestAddProjectAction = {
  type: typeof REQUEST_ADD_PROJECT
};

export type ReceiveAddProjectAction = {
  type: typeof RECEIVE_ADD_PROJECT,
  siteId: number,
  zoneId: number,
  project: ResourceAccessNode,
};

export type ErrorFetchAddProjectAction = {
  type: typeof ERROR_FETCH_ADD_PROJECT
};

export const requestAddProjectAction = (): RequestAddProjectAction => ({ type: REQUEST_ADD_PROJECT });
export const receiveAddProjectAction = (siteId: number, zoneId: number, project: ResourceAccessNode): ReceiveAddProjectAction => ({
  type: RECEIVE_ADD_PROJECT,
  siteId,
  zoneId,
  project,
});
export const errorFetchAddProjectAction = (): ErrorFetchAddProjectAction => ({ type: ERROR_FETCH_ADD_PROJECT });

export const addProject = (body: { siteId: number, zoneId: number, parentProjectId?: number, name: string }): AppThunk<Promise<void>> => async dispatch => {
  dispatch(requestAddProjectAction());
  return projectService
    .addProject(body)
    .then(createdProject => {
      dispatch(receiveAddProjectAction(body.siteId, body.zoneId, createdProject));
    })
    .catch(() => {
      dispatch(errorFetchAddProjectAction());
    });
};

/*
 * Delete Project
 */
export const RECEIVE_DELETE_PROJECT = 'RECEIVE_DELETE_PROJECT';
export const RECEIVE_DELETE_PROJECT_INSTANCES = 'RECEIVE_DELETE_PROJECT_INSTANCES';

export type ReceiveDeleteProjectAction = {
  type: typeof RECEIVE_DELETE_PROJECT,
  siteId: number,
  zoneId: number,
  project: ResourceAccessNode,
};

export type ReceiveDeleteProjectInstancesAction = {
  type: typeof RECEIVE_DELETE_PROJECT_INSTANCES,
  projectId: number
};

export const receiveDeleteProjectAction = (
  siteId: number,
  zoneId: number,
  project: ResourceAccessNode
): ReceiveDeleteProjectAction => ({
  type: RECEIVE_DELETE_PROJECT,
  siteId,
  zoneId,
  project,
});

export const receiveDeleteProjectInstancesAction = (
  projectId: number
): ReceiveDeleteProjectInstancesAction => ({ type: RECEIVE_DELETE_PROJECT_INSTANCES, projectId });

export const deleteProject = (
  siteId: number,
  zoneId: number,
  project: ResourceAccessNode
): AppThunk<Promise<void>> => async dispatch => projectService.deleteProject(project.resourceId).then(() => {
  dispatch(receiveDeleteProjectAction(siteId, zoneId, project));
  dispatch(receiveDeleteProjectInstancesAction(project.resourceId));
});

/**
 * Fetch project information
 */
export const REQUEST_PROJECT_INFORMATION = 'REQUEST_PROJECT_INFORMATION';
export const RECEIVE_PROJECT_INFORMATION = 'RECEIVE_PROJECT_INFORMATION';
export const ERROR_FETCH_PROJECT_INFORMATION = 'ERROR_FETCH_PROJECT_INFORMATION';
export const CLEAR_PROJECT_INFORMATION = 'CLEAR_PROJECT_INFORMATION';

export type RequestProjectInformationAction = {
  type: typeof REQUEST_PROJECT_INFORMATION
};

export type ReceiveProjectInformationAction = {
  type: typeof RECEIVE_PROJECT_INFORMATION,
  projectInformation: ProjectInfoDTO
};

export type ErrorFetchProjectInformationAction = {
  type: typeof ERROR_FETCH_PROJECT_INFORMATION
};

export type ClearProjectInfoAction = {
  type: typeof CLEAR_PROJECT_INFORMATION
};

export const requestProjectInformationAction = (): RequestProjectInformationAction => ({ type: REQUEST_PROJECT_INFORMATION });
export const receiveProjectInformationAction = (projectInformation: ProjectInfoDTO): ReceiveProjectInformationAction => ({
  type: RECEIVE_PROJECT_INFORMATION,
  projectInformation,
});
export const errorFetchProjectInformationAction = (): ErrorFetchProjectInformationAction => ({ type: ERROR_FETCH_PROJECT_INFORMATION });
export const clearProjectInfoAction = (): ClearProjectInfoAction => ({ type: CLEAR_PROJECT_INFORMATION });
export const getProjectInformation = (projectId): AppThunk<Promise<void | ProjectInfoDTO>> => async dispatch => {
  dispatch(requestProjectInformationAction());

  return projectService
    .getProjectInfo(projectId)
    .then((projectInformation) => {
      dispatch(receiveProjectInformationAction(projectInformation));
      return projectInformation;
    })
    .catch(() => {
      dispatch(errorFetchProjectInformationAction());
    });
};

export type ProjectActionType = RequestAddProjectAction
| ReceiveAddProjectAction
| ErrorFetchAddProjectAction
| ReceiveDeleteProjectAction
| ReceiveDeleteProjectInstancesAction
| RequestProjectInformationAction
| ReceiveProjectInformationAction
| ErrorFetchProjectInformationAction
| ClearProjectInfoAction;