import { ResourceAccessNode, ResourceAccessTree } from 'types';

const RERSOURCE_PATH_DELIMITER = '/';

export default {
  splitPath(path: string): string[] {
    if (path == null) return [];
    return path.substring(1).split(RERSOURCE_PATH_DELIMITER);
  },
  getResource(resourceAccessTree: ResourceAccessTree, humanReadablePath: string): ResourceAccessNode | undefined {
    const nodeNames = this.splitPath(humanReadablePath);
    let currentNode = resourceAccessTree.sites.find(site => site.label ? site.label === nodeNames[0] : site.name === nodeNames[0]);
    nodeNames.shift();//Remove first element
    nodeNames.forEach(name => {
      currentNode = currentNode?.children.find(resource => resource.label ? resource.label === name : resource.name === name);
    });

    return currentNode;
  },
  getParentFromResource(sites: ResourceAccessNode[], resource: ResourceAccessNode): ResourceAccessNode | undefined {
    const nodeIds = this.splitPath(resource.resourcePath);
    let currentNode = sites.find(site =>  site.resourceId === parseInt(nodeIds[0]) );
    nodeIds.shift();//Remove first element
    nodeIds.pop();//Remove last element
    nodeIds.forEach(id => {
      currentNode = currentNode?.children.find(resource => resource.resourceId === parseInt(id));
    });

    return currentNode;
  },
};
