import { ConnectedProps, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import vmInstanceService from 'services/vm-instance-service';
import { useAppDispatch } from 'store';
import { addMachineTemplates } from 'store/actions/machine-template-actions';
import { initializeVMInstance } from 'store/actions/vm-instance-actions';
import { RootState } from 'store/reducers';
import { getSiteAvailableMachineTemplatesFromState } from 'store/selectors/machine-template-selectors';
import { ResourceAccessNode } from 'types';
import ProjectListItem from './project-list-item';

const mapState = (state: RootState) => ({
  getSiteAvailableMachineTemplatesFromState: (siteId) => getSiteAvailableMachineTemplatesFromState(state, siteId),
});

const connector = connect(mapState);

type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = {
  site: ResourceAccessNode,
  zone: ResourceAccessNode,
  project: ResourceAccessNode
};

type Props = ReduxProps & OwnProps;

const Project = ({ site, zone, project, getSiteAvailableMachineTemplatesFromState }: Props) => {
  const dispatch = useAppDispatch();

  const preInitializeVMInstance = () => {
    const vmInstanceInit = {
      machineTemplateId: -1,
      resolution: {
        width: -1,
        height: -1,
      },
      managerMode: false,
      siteId: site.resourceId,
      zoneId: zone.resourceId,
      projectId: project.resourceId,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    const availableMachineTemplates = getSiteAvailableMachineTemplatesFromState(site.resourceId);
    if (!availableMachineTemplates) {
      vmInstanceService.getMachineTemplates(site.resourceId).then((templates) => {
        const result = { [site.resourceId]: templates };
        dispatch(addMachineTemplates(result));
      });
    }
    dispatch(initializeVMInstance(vmInstanceInit));
    redirectToSessions();
  };

  const history = useHistory();

  const redirectToSessions = () => {
    history.push('/');
  };

  return (
    <ProjectListItem project={project} zone={zone} site={site} preInitializeVMInstance={preInitializeVMInstance}/>
  );
};

export default connector(Project);
