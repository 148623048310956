import { DateTime } from 'luxon';

export const isExpiredDate = (date: string): boolean => {
  if (!date) {
    return true;
  }

  return (
    DateTime.fromISO(date)
      .diff(DateTime.fromISO(DateTime.local()))
      .toObject().milliseconds <= 0
  );
};

export const expiresInNbMonths = (date: string, nbMonth: number): boolean => {
  if (!date) return false;

  return isExpiredDate(DateTime.fromISO(date).minus({ months: nbMonth }));
};

export const formatDateTimeFromMillis = (millis: number): string => {
  if (!millis) return '';
  return DateTime.fromMillis(millis).toFormat('yyyy/LL/dd HH:mm:ss'); // Enable sort on tables
};

export const formatDateFromISO = (isoDate: string): string => {
  if (!isoDate) return '';
  return DateTime.fromISO(isoDate).toFormat('yyyy/LL/dd'); // Enable sort on tables
};

export const formatDateFromMillis = (millis: number): string => {
  if (!millis) return '';
  return DateTime.fromMillis(millis).toFormat('yyyy/LL/dd'); // Enable sort on tables
};
export const convertSecondsToHours = (seconds: number): number => {
  return seconds / 3600;
};
export const convertHoursToSeconds = (hrs: number): number => {
  return hrs * 3600;
};

export const convertUTCDateToLocalDate = (isoDate: string): string => {
  if (isoDate) {
    return new Date(isoDate).toLocaleString( 'sv', { timeZoneName: 'short' } );
  }
  return isoDate;
};
