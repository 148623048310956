import React, { ReactElement, useState } from 'react';

import { Grid, Slider, Button, Checkbox, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton, Input, NativeSelect } from '@mui/material';

import TextLimited from 'components/shared/text-limited/text-limited';
import { format1Decimal, round1Decimal } from 'services/float-service';
import Loader from 'components/loader/loader';
import zoneService from 'services/zone-service';
import { BACKUP_TYPES } from 'constants/backup-policy';
import EditIcon from '@mui/icons-material/Edit';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import { StorageBackupType, ZoneInfoDTO } from 'types';
import styles from './edit-zone.module.scss';

type Props = {
  zone: ZoneInfoDTO;
  onUpdate: (size: number, snapshotsEnabled: boolean, backupSolution: StorageBackupType) => Promise<void>;
};

const EditZone = ({ zone, onUpdate }: Props): ReactElement => {
  const [oldFileshareSize, setOldFileshareSize] = useState(round1Decimal(zone.fileshareSize / 1024)); // convert from GiB to TiB
  const [newFileshareSize, setNewFileshareSize] = useState(oldFileshareSize);
  const [isUpdatingZone, setIsUpdatingZone] = useState(false);

  const [oldSnapshotsCheckboxChecked, setOldSnapshotsCheckboxChecked] = useState(zone.snapshotPolicy?.snapshotsEnabled ? zone.snapshotPolicy.snapshotsEnabled : false);
  const [newSnapshotsCheckboxChecked, setNewSnapshotsCheckboxChecked] = useState(oldSnapshotsCheckboxChecked);
  const snapshotsActivable = zone.storageTemplate?.snapshotsActivable ? zone.storageTemplate.snapshotsActivable : false;
  const [oldNativeBackupsEnabled, setOldNativeBackupsEnabled] = useState(zone.backupPolicy ? zone.backupPolicy.backupsEnabled : false);
  const [newNativeBackupsEnabled, setNewNativeBackupsEnabled] = useState(oldNativeBackupsEnabled);
  const [selectedBackupSolutionCode, setSelectedBackupSolutionCode] = useState(getZoneBackupSolution()); 
  const [showModal, setShowModal] = useState(false);

  const minFileshareSizeInTiB = zone?.storageTemplate?.minSize && zone?.storageTemplate?.minSize >= 100 ? round1Decimal(zone.storageTemplate.minSize / 1024) : 0.1;
  const maxFileshareSizeInTiB = zone?.storageTemplate?.maxSize ? round1Decimal(zone.storageTemplate.maxSize / 1024) : 100;
  const currencySign = zone?.pricingInfos?.currencyCode ? zone?.pricingInfos?.currencyCode : 'USD';
  const zonePrice = zone?.pricingInfos?.price ? zone.pricingInfos.price : 0.0;
  const sliderStep = zone?.storageTemplate?.sizeIncrement && zone?.storageTemplate?.sizeIncrement >= 100 ? round1Decimal(zone.storageTemplate.sizeIncrement / 1024) : 0.1;

  const [openModal, setOpenModal] = useState(false);

  const loadModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    setSelectedBackupSolutionCode(getZoneBackupSolution());
    setNewSnapshotsCheckboxChecked(oldSnapshotsCheckboxChecked);
    setNewNativeBackupsEnabled(oldNativeBackupsEnabled);
    setNewFileshareSize(oldFileshareSize);
  };

  const handleSnapshotsCheckboxChange = (event) => {
    setNewSnapshotsCheckboxChecked(event.target.checked);
  };

  function getZoneBackupSolution() : string {
    if (zone.storageTemplate.backupsSolutions.length == 1) {
      return zone.storageTemplate.backupsSolutions[0].code;
    }
    // There is no backup policy for batch backups
    const code = zone.storageTemplate.backupsSolutions.filter(bs => bs.deactivable == zone.backupPolicy?.backupsEnabled).shift()?.code;
    return code ? code : BACKUP_TYPES.NO_BACKUP;
  }

  const handInputBlurEvent = () => {
    let value: number;
    if (newFileshareSize < minFileshareSizeInTiB)
      value = minFileshareSizeInTiB;
    else if (newFileshareSize > maxFileshareSizeInTiB)
      value = maxFileshareSizeInTiB;
    else
      value = Math.round(newFileshareSize / sliderStep) * sliderStep;

    setNewFileshareSize(round1Decimal(value));
  };

  const handleInputZoneFileshareSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewFileshareSize(event.target.value === '' ? 0 : Number(event.target.value));
  };

  function getBackupSolution() : StorageBackupType {
    if (zone.storageTemplate.backupsSolutions.length > 1) {
      return zone.storageTemplate.backupsSolutions.filter(bs => bs.deactivable == newNativeBackupsEnabled)[0];
    } else {
      return zone.storageTemplate.backupsSolutions[0];
    }
  }

  const handleStorageBackupTypeChange = (event) => {
    setSelectedBackupSolutionCode(event.target.value);
    setNewNativeBackupsEnabled(event.target.value === BACKUP_TYPES.ANF_NATIVE_BACKUP);
    if (event.target.value === BACKUP_TYPES.ANF_NATIVE_BACKUP) {
      setNewSnapshotsCheckboxChecked(true);
    }
  };

  const handleNewFileshareSizeChange = (event, value) => {
    if (value < minFileshareSizeInTiB) {
      setNewFileshareSize(minFileshareSizeInTiB);
      return;
    }

    setNewFileshareSize(value);
  };

  const handleClose = (event) => {
    setShowModal(false);
    event.stopPropagation();
  };

  const handleClickOnUpdate = () => {
    const deleteSNapshots = oldSnapshotsCheckboxChecked && !newSnapshotsCheckboxChecked;
    const deleteBackups = oldNativeBackupsEnabled && !newNativeBackupsEnabled;
    if (deleteSNapshots || deleteBackups) {
      setShowModal(true);
    } else {
      onPreUpdate();
    }
  };

  const handleClickOnOkForUpdate = (event) => {
    handleClose(event);
    onPreUpdate();
  };

  const onPreUpdate = () => {
    setIsUpdatingZone(true);
    const isSnapshotsEnabled = snapshotsActivable ? newSnapshotsCheckboxChecked : false;
    const backupSolution = getBackupSolution();
    const isNativeBackupsEnabled = backupSolution.code === BACKUP_TYPES.ANF_NATIVE_BACKUP;
    // convert from TiB to GiB and round to the nearest sizeIncrement GiB
    const newFileshareSizeInGiB = Math.round(newFileshareSize * 1024 / zone.storageTemplate.sizeIncrement) * zone.storageTemplate.sizeIncrement;

    return onUpdate(newFileshareSizeInGiB, isSnapshotsEnabled, backupSolution)
      .then(() => {
        setOldFileshareSize(newFileshareSize);
        setOldSnapshotsCheckboxChecked(isSnapshotsEnabled);
        setNewSnapshotsCheckboxChecked(isSnapshotsEnabled);
        setOldNativeBackupsEnabled(isNativeBackupsEnabled);
        setNewNativeBackupsEnabled(isNativeBackupsEnabled);
        setIsUpdatingZone(false);
      })
      .catch(() => setIsUpdatingZone(false))
      .finally(() => setOpenModal(false));
  };

  return (
    <div className='edit-site-container'>

        <div>
            <IconButton
                onClick={loadModal}
                key={zone.id}
                classes={{
                  root: 'edit-button-color',
                }}>
                <EditIcon className='edit-icon-site'/>
            </IconButton>
        </div>

        <Dialog classes={{ paper: 'dialog-container' }} open={openModal} maxWidth='md' onClose={closeModal} className='dialog'>
            <DialogTitle>
                <div className='dialogTitle'>
                    <div className='modalTitle'>EDIT STORAGE</div>
                </div>
                <div className='edit-modal-site-name'>{zone.name || ''}</div>
            </DialogTitle>
            <DialogContent classes={{ root: 'overflow-y-visible' }}>
                <Grid className='root'>
                    <div className='header'>
                        <TextLimited text={`${zone.name} (${zone.siteName})`} limit={44} />
                    </div>
                    <div className='content'>
                        <div>
                            <span className='label'>Storage type: </span>
                            <span>{`${zone.storageType}`}</span>
                        </div>
                        <div>
                            <span className='label'>Storage size: </span>
                            <span>{`${format1Decimal(oldFileshareSize)} TiB`}</span>
                        </div>
                        <div>
                            <span className='label'>Current price: </span>
                            {zonePrice > 0.0 ? 
                              (<span>{`${zoneService.calculateStoragePrice(oldFileshareSize, zonePrice, oldNativeBackupsEnabled)} ${currencySign} / Month`}</span>)
                              : (<span>Unknown</span>)
                            }
                        </div>
                        {snapshotsActivable
                        && (
                            <div>
                                <span className='label'>Snapshots:</span>
                                <Checkbox 
                                  name='snapshot' 
                                  checked={newSnapshotsCheckboxChecked}
                                  disabled={selectedBackupSolutionCode === BACKUP_TYPES.ANF_NATIVE_BACKUP}
                                  onChange={handleSnapshotsCheckboxChange} />
                            </div>
                        )}
                        {
                        zone
                        && zone.storageTemplate
                        && (
                          <div>
                            <span className='label'>Backups:</span>
                            <NativeSelect
                              className={styles.create_resource__input_field}
                              value={selectedBackupSolutionCode}
                              onChange={event => handleStorageBackupTypeChange(event)}
                              disabled={zone.storageTemplate.backupsSolutions.length <= 1}>
                              {
                                  zone.storageTemplate.backupsSolutions
                                    .map((backupSolution) => {
                                      return (
                                      <option key={backupSolution.code} value={backupSolution.code} className={styles.create_resource__input_field}>
                                        {backupSolution.label}
                                      </option>
                                      );
                                    })
                              }
                            </NativeSelect>

                          </div>
                        )}
                        
                        <div>
                        <span className='label'>Set size: </span>
                          <div className={styles.slider_input}>
                            <Slider
                              className={styles.fileshare_slider}
                              value={newFileshareSize}
                              min={minFileshareSizeInTiB}
                              max={maxFileshareSizeInTiB}
                              step={sliderStep}
                              aria-labelledby='label'
                              onChange={handleNewFileshareSizeChange}/>
                            <Input
                              value={newFileshareSize}
                              onChange={e => handleInputZoneFileshareSizeChange(e)}
                              onBlur={() => handInputBlurEvent()}
                              inputProps={{
                                min: minFileshareSizeInTiB,
                                max: maxFileshareSizeInTiB,
                                step: sliderStep,
                                type: 'number',
                              }}/>
                          </div>
                        </div>
                        <div className='new-fileshare-size-container' style={{ visibility: newFileshareSize === oldFileshareSize 
                            && newSnapshotsCheckboxChecked === oldSnapshotsCheckboxChecked && newNativeBackupsEnabled === oldNativeBackupsEnabled ? 'hidden' : 'visible' }}>
                            <div >
                                <div>
                                    <span className='label'>New Storage size: </span>
                                    <span>{`${format1Decimal(newFileshareSize)} TiB`}</span>
                                </div>
                                <div>
                                    <span className='label'>New price: </span>
                                    {zonePrice > 0.0 ? 
                                      (<span>{`${zoneService.calculateStoragePrice(newFileshareSize, zonePrice, newNativeBackupsEnabled)} ${currencySign} / month`}</span>)
                                      : (<span>Unknown</span>)
                                    }
                                </div>
                            </div>

                            <div>
                                <Dialog open={showModal} onClose={handleClose} fullWidth maxWidth='sm' aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
                                <DialogTitle>
                                  {oldSnapshotsCheckboxChecked && !newSnapshotsCheckboxChecked && !oldNativeBackupsEnabled
                                    && (
                                      <div className='dialogTitle'>Disable snapshots</div>
                                    )}
                                  {oldNativeBackupsEnabled && !newNativeBackupsEnabled && newSnapshotsCheckboxChecked
                                    && (
                                      <div className='dialogTitle'>Disable backups</div>
                                    )}
                                    {oldSnapshotsCheckboxChecked && !newSnapshotsCheckboxChecked && oldNativeBackupsEnabled
                                    && (
                                      <div className='dialogTitle'>Disable snapshots and backups</div>
                                    )}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                    {oldSnapshotsCheckboxChecked && !newSnapshotsCheckboxChecked && !oldNativeBackupsEnabled
                                    && (
                                      <p>If you disable snapshots, <b>all snapshots will be deleted and won't be recoverable.</b> Do you want to proceed ?</p>
                                    )}
                                  {oldNativeBackupsEnabled && !newNativeBackupsEnabled && newSnapshotsCheckboxChecked
                                    && (
                                      <p>If you disable backups, <b>all backups will be deleted and won't be recoverable.</b> Do you want to proceed ?</p>
                                    )}
                                    {oldSnapshotsCheckboxChecked && !newSnapshotsCheckboxChecked && oldNativeBackupsEnabled
                                    && (
                                      <p>If you disable snapshots and backups, <b>all snapshots and backups will be deleted and won't be recoverable.</b> Do you want to proceed ?</p>
                                    )}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Grid container alignItems='stretch' direction='row' justifyContent='center' className='accessAttributionButtonGrid'>
                                    <Grid item>
                                        <Button onClick={handleClose} className='cancelButton' sx={{ color: 'black' }}>
                                        Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={handleClickOnOkForUpdate} variant='contained' color='primary' className='validationButton'>
                                        OK
                                        </Button>
                                    </Grid>
                                    </Grid>
                                </DialogActions>
                                </Dialog>
                            </div>
                        </div>
                        <div>
                            <span className='warning' style={{ display: 'inline-flex', alignItems: 'center' }}>
                              <br/>
                              <ReportProblemIcon style={{ color: 'orange', paddingRight: '6px' }}/>
                              <b>Warning: updating storage size might take several minutes.</b>
                              <b>Warning: some storage types have a limited rate regarding configuration change.</b>
                            </span>
                        </div>
                    </div>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container alignItems='stretch' direction='row' justifyContent='flex-end' className='dialog-actions'>
                    <Grid item>
                        <Button onClick={closeModal} className='cancel-button' sx={{ color: 'black' }}>
                            Cancel
                        </Button>
                    </Grid>

                    {!isUpdatingZone && (
                        <Grid item>
                        <Button
                            variant='contained'
                            color='primary'
                            className='validation-button'
                            onClick={handleClickOnUpdate}>
                            VALIDATE
                        </Button>
                        </Grid>
                    )}
                    {isUpdatingZone && <Loader className='update-loader' />}
                    
                </Grid>
            </DialogActions>
        </Dialog>

    </div>
  );
};

export default EditZone;
